.cke_description{
    width: 99% !important;
}

 .bounceup {
     animation: bounce2 2s ease infinite;
 }

 * {
     box-sizing: border-box;
     padding: 0;
     margin: 0;
     font-family: "NerisLight";
 }

 @font-face {
     font-family: "Gotham";
     src: local("Gotham"), url(../src/fonts/gotham/Gotham-Black.ttf);
 }

 @font-face {
     font-family: "NerisLight";
     src: local("NerisLight"), url(../src/fonts/neris/Neris-Light.ttf);
 }

 @font-face {
     font-family: "NerisBold";
     src: local("NerisBold"), url(../src/fonts/neris/Neris-Black.ttf);
 }
 h1,
 h2,
 h3 {
     font-family: "Gotham";
 }

 h4,
 h5,
 h6 {
     font-family: "NerisBold";
 }

 .loader {
     border: 5px solid #f3f3f3;
     border-radius: 50%;
     border-top: 5px solid rgb(174, 0, 255);
     border-bottom: 5px solid rgb(174, 0, 255);
     width: 50px;
     height: 50px;
     -webkit-animation: spin 3s linear infinite;
     animation: spin 3s linear infinite;
 }

 @keyframes bounce2 {

     0%,
     20%,
     50%,
     80%,
     100% {
         transform: translateY(0);
     }

     40% {
         transform: translateY(-9px);
     }

     60% {
         transform: translateY(-8px);
     }
 }

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}